( function( $ ) {
	
	$('.on-page-nav__content a').on('click', function(e){
		e.preventDefault();
		let nav_item_ID = $(this).attr('href');
		if( nav_item_ID.charAt( 0 ) === '#' ){
			nav_item_ID = nav_item_ID.slice( 1 );
			document.getElementById(nav_item_ID).scrollIntoView();
		}	
	});
	
	
	$('.component--sticky-accordion .accordion-item__trigger').on('click', function(e){
		$('.component--sticky-accordion .accordion-item__trigger[aria-expanded="true"]').not(this).click();
		
		$(this).find('.accordion__item-title-text').toggleClass('screen-reader-text');
		
	});
	
	function surveyCheck(){
		if($('.smcx-widget').length >= 1){
			$('#surveymonkey2').append($('.smcx-widget'));
			console.log('stop checking');
			clearTimeout(surveyCheckChecker);
		}else{
			surveyCheckChecker = setTimeout(function(){
				console.log('check');
				surveyCheck();
			}, 150);
		}
	}
		
	
	$('#feedback-survey-start-button').on('click', function(e){
		
		//var en_survey = '<script></script>';
		
		if(!$('#surveymonkey2').hasClass('has-survey')){
			
			$('#surveymonkey2').addClass('has-survey');
			
			$.getScript("/wp-content/plugins/mxw_enhancment_suite/assets/js/feedback_survey-en.js", function(){
				console.log('got script');
				
				surveyCheck();
				
			});
			
			//$('#surveymonkey2').addClass('has-survey').append(en_survey);
			//var addedScript = $('#surveymonkey2').find('script').text();
			//console.log(addedScript);
			//eval(addedScript);
		}
		
	})
	
	
} )( jQuery );