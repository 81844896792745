( function( $ ) {
	
	$(document).ready(function(){
		
		var imageGridRotatorOptions = {
			arrows: true,
			  slidesToShow: 4,
			  responsive: [
				{
				  breakpoint: 915,
				  settings: {
					slidesToShow: 3,
				  }
				},
				{
				  breakpoint: 675,
				  settings: {
					slidesToShow: 2,
				  }
				},
				{
				  breakpoint: 450,
				  settings: {
					slidesToShow: 1,
					adaptiveHeight: true,
				  }
				},
			  ],
		};
		
		var testimonialRotatorOptions = {
			slidesToShow: 2,
			slidesToScroll: 2,
			arrows: true,
			responsive: [
				{
				  breakpoint: 660,
				  settings: {
					  adaptiveHeight: true,
					slidesToShow: 1,
					slidesToScroll: 1,
				  }
				}
			],
		};
		
		if($('html').attr('dir') == 'rtl'){
			imageGridRotatorOptions['rtl'] = true;
			testimonialRotatorOptions['rtl'] = true;
		}
		
		$('.block--small-image-grid.block-style--rotator .item-list').slick(imageGridRotatorOptions);	
		$('.block--testimonials.block-style--rotator .item-list').slick(testimonialRotatorOptions);
		
		
		if($('.block--small-image-grid.block-style--rotator .item-list').hasClass('item-count--2')){
			
		}
		
		$('.gallery-display').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: true,
			fade: true,
			asNavFor: '.gallery-nav',
			adaptiveHeight: true,
		});
		$('.gallery-nav').slick({
			slidesToShow: 5,
			slidesToScroll: 5,
			asNavFor: '.gallery-display',
			dots: true,
			centerMode: true,
		});
			
			
	});
	
	
} )( jQuery );